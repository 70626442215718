<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:39:03
 * @ Description: Fixer's professional view page in the user's professional service dashboard section of the app.
 -->

<template>
    <v-row
        justify="center"
    >
        <!-- Desktop -->
        <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
            cols="12"
        >
            <v-form
                v-model="validServiceForm"
                ref="serviceForm"
            >
                <v-row>
                    <!-- Back Button & Service Name -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <v-btn
                            icon
                            @click="back($store.state.storeRouter.currentRoute)"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-icon
                            color="#2E567A"
                            size="30"
                            class="mr-1"
                        >
                            {{ mdiAccountSupervisorOutline }}
                        </v-icon>
                        {{ selectedProfessionalService.name }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider
                            style="width: 50%;"
                        />
                    </v-col>
                    <!-- Back Button & Service Name -->

                    <!-- Services Form -->
                    <v-col
                        cols="6"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pb-0 os-15-sb"
                            >
                                Service Name
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.name">{{ selectedProfessionalService.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No name added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Industry
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.industry.id">{{ selectedProfessionalService.industry.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No industry added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Service Description
                                <br />
                                <div
                                    v-if="selectedProfessionalService.description"
                                    class="os-13-r pt-3"
                                    style="color: #2E567A;"
                                    v-html="selectedProfessionalService.description"
                                />
                                <div
                                    v-else-if="!selectedProfessionalService.description"
                                    class="os-12-r py-3"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                >
                                    No description added
                                </div>
                            </v-col>

                            <!-- Location -->
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Service Location
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.work_location.id">{{ selectedProfessionalService.work_location.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No location added
                                    </span>
                                </div>
                            </v-col>
                                    
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                                v-if="selectedProfessionalService.work_location.id !== 2"
                            >
                                Addresses
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <v-row
                                        v-for="(item, index) in addresses"
                                        :key="index"
                                        justify="center"
                                        class="mx-3 my-1"
                                    >
                                        <v-col
                                            class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                            align-self="center"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                                size="20"
                                                class="mb-1"
                                            >
                                                {{ mdiMapMarkerOutline }}
                                            </v-icon>
                                            <span>{{ item.address_line_1 }}</span>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>

                            <!-- Map -->
                            <v-col
                                cols="12"
                                class="pt-0"
                                v-if="selectedProfessionalService.work_location.id !== 2"
                            >
                                <general-map-component
                                    :mapMarkers="mapMarkers"
                                    :avatar="$store.state.user.userData.avatar"
                                />
                            </v-col>
                            <!-- Map -->
                            <!-- Location -->

                            <v-col
                                cols="12"
                                class="pt-0 os-15-sb"
                            >
                                Rate
                                <br />
                                <div
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedProfessionalService.interval.id < 4">{{ selectedProfessionalService.currency.symbol }}{{ selectedProfessionalService.rate }} per {{ selectedProfessionalService.interval.name }}</span>
                                    <span>Quote Upon Request</span>
                                    <!-- <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No rate added
                                    </span> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                    <!-- Image & Request Button -->
                    <v-col
                        class="os-13-r text-center px-0"
                        cols="3"
                    >
                        <div
                            style="min-height: 100px; display: flex; justify-content: center;"
                        >
                            <v-avatar
                                :size="selectedProfessionalService.image ? '100px' : '50px'"
                                class="mb-2"
                            >
                                <v-img
                                    :src="selectedProfessionalService.image ? selectedProfessionalService.image : '/general/FixerIndividualIcon.png'"
                                    contain
                                />
                            </v-avatar>
                        </div>
                        <div
                            class="py-6"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="$router.push(`/service/${$route.params.id}/request`)"
                                v-if="$store.state.user.userData.id !== selectedProfessionalService.owner.id"
                            >
                                Request
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Image & Request Button -->
                </v-row>
            </v-form>
        </v-col>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-col
            v-if="$vuetify.breakpoint.smAndDown"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            cols="12"
        >
            <v-form
                v-model="validServiceForm"
                ref="serviceForm"
            >
                <!-- Back Button & Service Name -->
                <v-col
                    cols="12"
                    class="os-22-sb"
                >
                    <v-btn
                        icon
                        @click="back($store.state.storeRouter.currentRoute)"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    <v-icon
                        color="#2E567A"
                        size="30"
                        class="mr-1"
                    >
                        {{ mdiAccountSupervisorOutline }}
                    </v-icon>
                    {{ selectedProfessionalService.name }}
                </v-col>
                <v-col
                    cols="12"
                    class="py-0"
                >
                    <v-divider />
                </v-col>
                <!-- Back Button & Service Name -->

                <!-- Image & Edit Button -->
                <v-col
                    class="os-13-r text-center px-0"
                    cols="12"
                >
                    <div
                        style="min-height: 100px; display: flex; justify-content: center;"
                    >
                        <v-avatar
                            size="100"
                            class="mb-2"
                        >
                            <v-img
                                :src="selectedProfessionalService.image ? selectedProfessionalService.image : '/general/FixerIndividualIcon.png'"
                                contain
                            />
                        </v-avatar>
                    </div>
                    <div
                        class="pt-6"
                    >
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            @click="$router.push(`/service/${$route.params.id}/request`)"
                            v-if="$store.state.user.userData.id !== selectedProfessionalService.owner.id"
                        >
                            Request
                        </v-btn>
                    </div>
                </v-col>
                <!-- Image & Edit Button -->
                    
                <v-row>
                    <!-- Services Form -->
                    <v-col
                        cols="12"
                        class="pb-0 os-15-sb"
                        align-self="end"
                    >
                        Service Name
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.name">{{ selectedProfessionalService.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No name added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                    >
                        Industry
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.industry.id">{{ selectedProfessionalService.industry.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No industry added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                    >
                        Service Description
                        <br />
                        <div
                            v-if="selectedProfessionalService.description"
                            class="os-13-r pt-3"
                            style="color: #2E567A;"
                            v-html="selectedProfessionalService.description"
                        />
                        <div
                            v-else-if="!selectedProfessionalService.description"
                            class="os-12-r py-3"
                            style="color: rgba(0, 0, 0, 0.38);"
                        >
                            No description added
                        </div>
                    </v-col>

                    <!-- Location -->
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                    >
                        Service Location
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.work_location.id">{{ selectedProfessionalService.work_location.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No location added
                            </span>
                        </div>
                    </v-col>
                                    
                    <v-col
                        cols="12"
                        class="py-0 os-15-sb"
                        v-if="selectedProfessionalService.work_location.id !== 2"
                    >
                        Addresses
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <v-row
                                v-for="(item, index) in addresses"
                                :key="index"
                                justify="center"
                                class="mx-3 my-1"
                            >
                                <v-col
                                    class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                    align-self="center"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        size="20"
                                        class="mb-1"
                                    >
                                        {{ mdiMapMarkerOutline }}
                                    </v-icon>
                                    <span>{{ item.address_line_1 }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                    <!-- Map -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-if="selectedProfessionalService.work_location.id !== 2"
                    >
                        <general-map-component
                            :mapMarkers="mapMarkers"
                            :avatar="$store.state.user.userData.avatar"
                        />
                    </v-col>
                    <!-- Map -->
                    <!-- Location -->

                    <v-col
                        cols="12"
                        class="pt-0 os-15-sb"
                    >
                        Rate
                        <br />
                        <div
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedProfessionalService.interval.id < 4">{{ selectedProfessionalService.currency.symbol }}{{ selectedProfessionalService.rate }} per {{ selectedProfessionalService.interval.name }}</span>
                            <span>Quote Upon Request</span>
                            <!-- <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No rate added
                            </span> -->
                        </div>
                    </v-col>
                    <!-- Services Form -->
                </v-row>
            </v-form>
        </v-col>
        <!-- Mobile -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->
    </v-row>
</template>
<script>
    import { mdiFlashOutline, mdiPlusCircleOutline, mdiPlusCircle, mdiMinusCircle, mdiArrowLeftThinCircleOutline, mdiClose, mdiAccountSupervisorOutline, mdiMapMarkerOutline } from '@mdi/js'
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralMapComponent from '@/components/general/map/GeneralMapComponent.vue'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import { GeneralInvoiceController, LookupJobController, LookupIndustryController, LookupJobTitleController, LookupLocationController, WorkProfessionalServiceDetailController, WorkJobDocumentController, WorkJobLocationController, WorkJobMilestoneController, WorkJobStatusController } from '@/logic/controller/index.controller.js'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'

    export default {
        name: 'WorkProfessionalServiceActionGeneralViewPage',

        components: {
            // GeneralTipTapTextEditorComponent,
            GeneralMapComponent,
            // GeneralGoogleAutocompleteAddressComponent,
            PaymentOverlayComponent
        },

        watch: {
            '$route.params.id': {
                async handler (value) {
                    await this.getProfessionalServiceDetail()
                }
            }
        },

        data () {
            return {
                mdiFlashOutline,
                mdiPlusCircleOutline,
                mdiPlusCircle,
                mdiMinusCircle,
                mdiArrowLeftThinCircleOutline,
                mdiClose,
                mdiAccountSupervisorOutline,
                mdiMapMarkerOutline,
                selectedProfessionalService: {
                    currency: {},
                    industry: {},
                    interval: {},
                    work_location: {},
                    owner: {}
                },
                lookupJobController: null,
                lookupIndustryController: null,
                lookupJobTitleController: null,
                lookupLocationController: null,
                workProfessionalServiceDetailController: null,
                generalInvoiceController: null,
                address: {
                    job_id: null,
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                googlePlaceAltered: false,
                countries: [],
                mapMarkers: [],
                addresses: [],
                validServiceForm: false,
                nextPath: null,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false
            }
        },

        beforeMount () {
            this.lookupJobController = new LookupJobController()
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupJobTitleController = new LookupJobTitleController()
            this.lookupLocationController = new LookupLocationController()
            this.workProfessionalServiceDetailController = new WorkProfessionalServiceDetailController()
            this.workJobDocumentController = new WorkJobDocumentController()
            this.workJobLocationController = new WorkJobLocationController()
            this.workJobMilestoneController = new WorkJobMilestoneController()
            this.workJobStatusController = new WorkJobStatusController()
            this.generalInvoiceController = new GeneralInvoiceController()
        },

        async mounted () {
            await this.getProfessionalServiceDetail()
        },

        methods: {
            async getProfessionalServiceDetail () {
                try {
                    this.selectedProfessionalService = await this.workProfessionalServiceDetailController.retrieve(this.$route.params.id)

                    this.addresses = this.selectedProfessionalService.geo_location
                    for (let index = 0; index < this.selectedProfessionalService.geo_location.length; index++) {
                        const address = this.selectedProfessionalService.geo_location[index];
                        const mapMarkerIndex = this.mapMarkers.findIndex(mapMarkerToFind => mapMarkerToFind.lat === this.address.lat && mapMarkerToFind.lng === this.address.lng)
                        if (mapMarkerIndex < 0) this.mapMarkers.push({ lat: address.lat, lng: address.lng })
                    }
                    
                    // if (this.selectedProfessionalService.status.id === 1) this.editMode = true
                    // else this.editMode = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async back (nextPath) {
                if (this.editMode) {
                    this.showCancelEditConfirmationOverlayBoolean = true
                    this.nextPath = nextPath
                } else this.$router.push(nextPath)
            },

            googlePlaceSelected (place) {
                const name = this.address.name
                const user_id = this.address.user_id

                this.googlePlaceAltered = false
                
                Object.assign(this.address, place)
                this.address.name = name
                this.address.user_id = user_id

                const countryIndex = this.countries.findIndex(country => country.iso_2 === this.address.country.country_code)
                if (countryIndex > -1) this.address.country.id = this.countries[countryIndex].id

                this.mapMarkers = [
                    {
                        lat: place.lat,
                        lng: place.lng
                    }
                ]
            },

            addressChanged (address, googlePlaceAltered) {
                this.googlePlaceAltered = googlePlaceAltered
                this.address.address_line_1 = address
                this.autocompleteLocationValidate()
            },

            autocompleteLocationValidate () {
                if (this.address.address_line_1 === '' || (this.address.address_line_1.replace(/\s/g, '') === '')) {
                    this.address = {
                        job_id: this.selectedProfessionalService.job_id,
                        user_id: this.address.user_id,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            },

            async recruit (afterPayment) {
                try {
                    if (!afterPayment) await this.upsertJob(true)
                    await this.workJobStatusController.recruit(this.selectedProfessionalService)

                    this.selectedProfessionalService.status.id = 2

                    this.$store.commit('setSnackbar', {
                        text: 'Created successfully',
                        show: true,
                        color: '#069B34'
                    })

                    // this.editMode = false
                    this.$router.push('/job/pending')
                } catch (error) {
                    if (error.errorCode) {
                        this.invoiceData = error.invoiceData
                        this.showPaymentOverlay(true)
                    }
                    else {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            showPaymentOverlay (value, confirmRecruit, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmRecruit) this.payInvoice(this.invoiceData, selectedCard)
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    await this.recruit(true)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async downloadDocument(documentToDownload) {
                try {
                    const downloadResponse = await this.workJobDocumentController.download(this.$route.params.id, encodeURIComponent(documentToDownload.fullpath))

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style>
    .v-application p {
        margin-bottom: 12px;
    }
</style>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
